/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { type GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
    nodes: {
        Cylinder016: THREE.Mesh;
        Cylinder016_1: THREE.Mesh;
        Cylinder016_2: THREE.Mesh;
    };
    materials: {
        ['Red.025']: THREE.MeshStandardMaterial;
        ['White.025']: THREE.MeshStandardMaterial;
        ['BrownDark.018']: THREE.MeshStandardMaterial;
    };
};

export default function TargetStand(props: JSX.IntrinsicElements['group']) {
    const { nodes, materials } = useGLTF('/objects/target-stand.gltf') as GLTFResult;

    return (
        <group {...props} dispose={null}>
            <group rotation={[Math.PI / 2, 0, 0]}>
                <mesh geometry={nodes.Cylinder016.geometry} material={materials['Red.025']} />
                <mesh geometry={nodes.Cylinder016_1.geometry} material={materials['White.025']} />
                <mesh geometry={nodes.Cylinder016_2.geometry} material={materials['BrownDark.018']} />
            </group>
        </group>
    );
}

useGLTF.preload('/objects/target-stand.gltf');
