import styled from 'styled-components';

export const RainbowLetter = ({ letter, index }: { letter: string; index: number }) => {
    return (
        <Container $index={index}>
            <Rainbow color="#fff" $rainbowIndex={0}>
                {letter}
            </Rainbow>
            <Rainbow color="#D49C3D" $rainbowIndex={1}>
                {letter}
            </Rainbow>
            <Rainbow color="#D14B3D" $rainbowIndex={2}>
                {letter}
            </Rainbow>
            <Rainbow color="#CF52EB" $rainbowIndex={3}>
                {letter}
            </Rainbow>
            <Rainbow color="#44A3F7" $rainbowIndex={4}>
                {letter}
            </Rainbow>
            <Rainbow color="#5ACB3C" $rainbowIndex={5}>
                {letter}
            </Rainbow>
            <Rainbow color="#DEBF40" $rainbowIndex={6}>
                {letter}
            </Rainbow>
        </Container>
    );
};

const Container = styled.div<{ $index: number }>`
    counter-reset: rainbow;
    position: relative;
    display: block;

    padding: 0;
    margin: 0;

    z-index: ${({ $index }) => $index};
`;

const Rainbow = styled.div<{ color: string; $rainbowIndex: number }>`
    font-family: Plumpfull;
    font-size: 70px;
    font-weight: bold;
    letter-spacing: -2px;

    counter-increment: rainbow;
    color: ${({ color }) => color};

    text-shadow:
        -1px -1px 0 #31037d,
        1px -1px 0 #31037d,
        -1px 1px 0 #31037d,
        1px 1px 0 #31037d,
        4px 4px 0 rgba(0, 0, 0, 0.2);

    animation: rainbow 2s ease-in-out infinite;

    animation-delay: ${({ $rainbowIndex }) => $rainbowIndex / 10}s;
    left: ${({ $rainbowIndex }) => 2 * $rainbowIndex}px;
    z-index: ${({ $rainbowIndex }) => -$rainbowIndex * 10};

    &:not(:first-child) {
        position: absolute;
        top: 0;
    }

    &:first-child {
        text-shadow:
            -1px -1px 0 #31037d,
            1px -1px 0 #31037d,
            -1px 1px 0 #31037d,
            1px 1px 0 #31037d,
            4px 4px 0 rgba(0, 0, 0, 0.2),
            -20px 10px 10px rgba(0, 0, 0, 0.3),
            0 20px 50px rgba(0, 0, 0, 0.3);
    }

    will-change: transform;

    @keyframes rainbow {
        0%,
        100% {
            transform: translateY(1rem);
        }
        50% {
            transform: translateY(-1rem);
        }
    }
`;
