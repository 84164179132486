import { Text } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { ImageGallery } from './ImageGallery';
import { Highlights } from './Highlights';
import { Module } from '../models/powerBank/Module';

const highlights = [
    {
        description:
            'OneFlash provides portable battery stations in high-density locations: airports, shopping malls, stores, gyms, hospitals and festivals.\n' +
            '\n' +
            'We were tasked with developing middleware to communicate with the stations to unlock the portable batteries.\n' +
            '\n' +
            'Our objectives? To improve performance and scalability over the old middleware and to define a clear deployment process.\n' +
            '\n' +
            'To find out more, hover over the badges on the right.\n',
        imageUrl: '/images/badges/project.png',
        title: 'Project',
    },
    {
        description:
            'The stations communicate using the MQTT protocol. So we needed to set up an MQTT broker to route messages from the stations to the backend.\n' +
            '\n' +
            'To do this, we carried out a comparative study of different brokers and chose two: EMQX and VerneMQ. We chose EMQX because of its ease of installation.\n' +
            '\n' +
            'EMQX allows for clustering, which allowed us to create three clusters working together to improve resilience.\n',
        imageUrl: '/images/badges/emqx.png',
        title: 'MQTT',
    },
    {
        description:
            'We decided to deploy our solution on Kubernetes. This easy-to-use technology allows the customer to take control of the project once the mission is complete.\n' +
            '\n' +
            'Our Kubernetes cluster consists of several nodes, including one for the EMQX cluster (which requires more resources) and one for the API pods, which require autoscaling capabilities.\n' +
            '\n' +
            "We've set up the resources needed to keep the cluster running smoothly: an Ingress to access the different parts of the middleware using SSL, an HPA to manage API scaling, and so on.\n",
        imageUrl: '/images/badges/kubernetes.png',
        title: 'Kubernetes',
    },
    {
        description:
            'We used Google Cloud Platform to deploy our Kubernetes cluster.\n' +
            '\n' +
            'When deploying our database, we added a whitelist of IP addresses to increase security. To do this, we had to manage the access points of our cluster by turning it into a private cluster. We then created routing tools, such as a cloud NAT, to authorise internet access to our API.\n' +
            '\n' +
            'We wrote clear documentation that allowed us to reinstall the middleware from scratch (creating the Kubernetes cluster, Mongo database, VPC, etc.).\n',
        imageUrl: '/images/badges/gcp.png',
        title: 'Google Cloud',
    },
];

export const ProjectOneflash = () => {
    const { width } = useThree((state) => state.viewport);

    return (
        <group>
            <group position-x={width / 2 - width / 30}>
                <group>
                    <Text
                        anchorX="right"
                        fontSize={0.5}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="right"
                        lineHeight={1}
                        maxWidth={width - width / 10}
                    >
                        Oneflash - Never run out of battery
                        <meshBasicMaterial color={'#ffffff'} toneMapped={false} />
                    </Text>
                </group>

                <Highlights isRight highlights={highlights} />

                <ImageGallery
                    imageUrls={['/images/oneflash/1.png', '/images/oneflash/2.png', '/images/oneflash/3.png']}
                    isRight={true}
                    isDesktop
                />
            </group>
            <Module />
        </group>
    );
};
