/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { type GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
    nodes: {
        Cube005: THREE.Mesh;
        Cube005_1: THREE.Mesh;
        Cube005_2: THREE.Mesh;
        Cube005_3: THREE.Mesh;
        Cube005_4: THREE.Mesh;
        Cube005_5: THREE.Mesh;
        Cube005_6: THREE.Mesh;
    };
    materials: {
        Mat0: THREE.MeshStandardMaterial;
        Mat1: THREE.MeshStandardMaterial;
        Mat2: THREE.MeshStandardMaterial;
        Window_Frame: THREE.MeshStandardMaterial;
        Mat4: THREE.MeshStandardMaterial;
        Mat3: THREE.MeshStandardMaterial;
        Window: THREE.MeshStandardMaterial;
    };
};

export default function SpaceShip(props: JSX.IntrinsicElements['group']) {
    const { nodes, materials } = useGLTF('/objects/space-ship.gltf') as GLTFResult;
    return (
        <group {...props} dispose={null}>
            <mesh geometry={nodes.Cube005.geometry} material={materials.Mat0} />
            <mesh geometry={nodes.Cube005_1.geometry} material={materials.Mat1} />
            <mesh geometry={nodes.Cube005_2.geometry} material={materials.Mat2} />
            <mesh geometry={nodes.Cube005_3.geometry} material={materials.Window_Frame} />
            <mesh geometry={nodes.Cube005_4.geometry} material={materials.Mat4} />
            <mesh geometry={nodes.Cube005_5.geometry} material={materials.Mat3} />
            <mesh geometry={nodes.Cube005_6.geometry} material={materials.Window} />
        </group>
    );
}

useGLTF.preload('/objects/space-ship.gltf');
