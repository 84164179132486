import styled from 'styled-components';
import { TitleBouncingLetter } from '../letterEffect/TitleBouncingLetter';
import { useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';

export const EnigmoTitle = () => {
    const scrollText = useRef<HTMLDivElement>(null);
    const scroll = useScroll();

    useFrame(() => {
        if (!scrollText.current) {
            return;
        }
        const range1 = scroll.range(0, 0.5 / scroll.pages);
        scrollText.current.style.opacity = (1 - range1).toString();
    });

    return (
        <Container>
            <TitleContainer>
                <Title>
                    <TitleBouncingLetter letter={'E'} index={1} />
                    <TitleBouncingLetter letter={'N'} index={2} />
                    <TitleBouncingLetter letter={'I'} index={3} />
                    <TitleBouncingLetter letter={'G'} index={4} />
                    <TitleBouncingLetter letter={'M'} index={5} />
                    <TitleBouncingLetter letter={'O'} index={6} />
                </Title>
                <SubTitle>A freelancer company</SubTitle>
            </TitleContainer>
            <ScrollText ref={scrollText}>Scroll to continue</ScrollText>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
`;

const SubTitle = styled.div`
    color: white;
    font-size: 22px;
`;

const ScrollText = styled.div`
    margin-top: 50px;
    color: white;
    font-size: 26px;
    padding: 30px;
`;
