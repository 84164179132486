import styled from 'styled-components';
import { BouncingLetter } from '../letterEffect/BouncingLetter';

export const OurValues = () => {
    return (
        <Container>
            <TitleContainer>
                <Title>
                    <BouncingLetter letter={'O'} index={1} />
                    <BouncingLetter letter={'U'} index={2} />
                    <BouncingLetter letter={'R'} index={3} />
                    <div style={{ width: 20 }} />
                    <BouncingLetter letter={'V'} index={4} />
                    <BouncingLetter letter={'A'} index={5} />
                    <BouncingLetter letter={'L'} index={6} />
                    <BouncingLetter letter={'U'} index={7} />
                    <BouncingLetter letter={'E'} index={8} />
                    <BouncingLetter letter={'S'} index={9} />
                </Title>
            </TitleContainer>
            <PrinciplesContainer />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
`;

const PrinciplesContainer = styled.div`
    width: 100%;
    flex: 1;
`;
