import { Image } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { DoubleSide, Group, MathUtils } from 'three';

export const HighlightImage = ({
    imageUrl,
    imageIndex,
    imageWidth,
    selectedIndex,
    setSelectedIndex,
}: {
    imageUrl: string;
    imageIndex: number;
    imageWidth: number;
    selectedIndex: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const imageRef = useRef<Group>(null);

    useFrame((_, delta) => {
        if (!imageRef.current) {
            return;
        }
        const isSelected = selectedIndex === imageIndex;

        imageRef.current.rotation.y = MathUtils.damp(imageRef.current.rotation.y, isSelected ? Math.PI : 0, 4, delta);
    });

    return (
        <group
            onPointerMove={() => {
                setSelectedIndex(imageIndex);
            }}
        >
            <group position-z={0.1}>
                <Image url={imageUrl} transparent opacity={0} scale={[imageWidth, imageWidth]} side={DoubleSide} />
            </group>
            <group ref={imageRef}>
                <Image
                    url={imageUrl}
                    transparent
                    scale={[imageWidth, imageWidth]}
                    side={DoubleSide}
                    toneMapped={false}
                />
            </group>
        </group>
    );
};
