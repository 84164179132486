import styled from 'styled-components';
import { WaveLetter } from '../letterEffect/WaveLetter';
import { RainbowLetter } from '../letterEffect/RainbowLetter';

export const Contact = () => {
    return (
        <Container>
            <TitleContainer>
                <Title>
                    <RainbowLetter letter={'C'} index={0} />
                    <RainbowLetter letter={'O'} index={1} />
                    <RainbowLetter letter={'N'} index={2} />
                    <RainbowLetter letter={'T'} index={3} />
                    <RainbowLetter letter={'A'} index={4} />
                    <RainbowLetter letter={'C'} index={5} />
                    <RainbowLetter letter={'T'} index={6} />
                    <div style={{ width: 20 }} />
                    <RainbowLetter letter={'U'} index={6} />
                    <RainbowLetter letter={'S'} index={6} />
                </Title>
            </TitleContainer>
            <PrinciplesContainer />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
`;

const PrinciplesContainer = styled.div`
    width: 100%;
    flex: 1;
`;
