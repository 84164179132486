/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { type GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
    nodes: {
        bunBottom: THREE.Mesh;
        bunBottom_1: THREE.Mesh;
        bunTop: THREE.Mesh;
        cheese: THREE.Mesh;
        cheese_1: THREE.Mesh;
        salad: THREE.Mesh;
        salad_1: THREE.Mesh;
        Mesh_tomato: THREE.Mesh;
        Mesh_tomato_1: THREE.Mesh;
        patty: THREE.Mesh;
        patty_1: THREE.Mesh;
    };
    materials: {
        brownLight: THREE.MeshStandardMaterial;
        yellow: THREE.MeshStandardMaterial;
        green: THREE.MeshStandardMaterial;
        _defaultMat: THREE.MeshStandardMaterial;
        red: THREE.MeshStandardMaterial;
        brownDark: THREE.MeshStandardMaterial;
    };
};

export default function Burger(props: JSX.IntrinsicElements['group']) {
    const { nodes, materials } = useGLTF('/objects/burger.gltf') as GLTFResult;
    return (
        <group {...props} dispose={null}>
            <mesh geometry={nodes.bunBottom.geometry} material={nodes.bunBottom.material} scale={[1, 0.8, 1]} />
            <mesh geometry={nodes.bunBottom_1.geometry} material={nodes.bunBottom_1.material} position={[0, 0.13, 0]} />
            <mesh geometry={nodes.bunTop.geometry} material={nodes.bunTop.material} position={[0, 0.24, 0]} />
            <mesh
                geometry={nodes.cheese.geometry}
                material={nodes.cheese.material}
                position={[0, 0.07, 0]}
                scale={[2.79, 0.8, 1.43]}
            />
            <mesh
                geometry={nodes.cheese_1.geometry}
                material={nodes.cheese_1.material}
                position={[0, 0.21, 0]}
                scale={[2.79, 0.8, 1.43]}
            />
            <mesh
                geometry={nodes.salad.geometry}
                material={nodes.salad.material}
                position={[0, 0.04, 0]}
                scale={[0.92, 0.75, 0.92]}
            />
            <mesh
                geometry={nodes.salad_1.geometry}
                material={nodes.salad_1.material}
                position={[0, 0.17, 0]}
                scale={[0.92, 0.6, 0.92]}
            />
            <group position={[0, 0.11, 0]} scale={[1.56, 0.56, 1.56]}>
                <mesh geometry={nodes.Mesh_tomato.geometry} material={materials._defaultMat} />
                <mesh geometry={nodes.Mesh_tomato_1.geometry} material={materials.red} />
            </group>
            <mesh
                geometry={nodes.patty.geometry}
                material={nodes.patty.material}
                position={[0, 0.06, 0]}
                scale={[1, 0.91, 1]}
            />
            <mesh
                geometry={nodes.patty_1.geometry}
                material={nodes.patty_1.material}
                position={[0, 0.19, 0]}
                scale={[1, 0.91, 1]}
            />
        </group>
    );
}

useGLTF.preload('/objects/burger.gltf');
