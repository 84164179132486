/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Vancouver, but small by Michal Minecki [CC-BY] via Poly Pizza
*/

import * as THREE from 'three';
import { useGLTF, useMask } from '@react-three/drei';
import { type GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
    nodes: {
        mesh1656777012: THREE.Mesh;
        mesh1656777012_1: THREE.Mesh;
        mesh1971097135: THREE.Mesh;
        mesh1971097135_1: THREE.Mesh;
        group1452617283: THREE.Mesh;
        group1926126815: THREE.Mesh;
        group269306622: THREE.Mesh;
        group62122215: THREE.Mesh;
        group462179313: THREE.Mesh;
        group32002972: THREE.Mesh;
        group1859955707: THREE.Mesh;
        group1600036614: THREE.Mesh;
        group758244815: THREE.Mesh;
        group1297686250: THREE.Mesh;
        group81821235: THREE.Mesh;
        group840029287: THREE.Mesh;
        group644830360: THREE.Mesh;
        group264317860: THREE.Mesh;
        group740519829: THREE.Mesh;
        group1921196339: THREE.Mesh;
        group960292067: THREE.Mesh;
        mesh944212785: THREE.Mesh;
        mesh944212785_1: THREE.Mesh;
        group664967870: THREE.Mesh;
        mesh441989801: THREE.Mesh;
        mesh441989801_1: THREE.Mesh;
        mesh1533200203: THREE.Mesh;
        mesh1533200203_1: THREE.Mesh;
        mesh2085297235: THREE.Mesh;
        mesh2085297235_1: THREE.Mesh;
        mesh634886533: THREE.Mesh;
        mesh634886533_1: THREE.Mesh;
        mesh1023818653: THREE.Mesh;
        mesh1023818653_1: THREE.Mesh;
        mesh1895269739: THREE.Mesh;
        mesh1895269739_1: THREE.Mesh;
        mesh486381022: THREE.Mesh;
        mesh486381022_1: THREE.Mesh;
        group693226123: THREE.Mesh;
        mesh13433428: THREE.Mesh;
        mesh13433428_1: THREE.Mesh;
        mesh747770077: THREE.Mesh;
        mesh747770077_1: THREE.Mesh;
        mesh1755377577: THREE.Mesh;
        mesh1755377577_1: THREE.Mesh;
        group394304578: THREE.Mesh;
        mesh1148249337: THREE.Mesh;
        mesh1148249337_1: THREE.Mesh;
        group2072235421: THREE.Mesh;
        group381120477: THREE.Mesh;
        group305972276: THREE.Mesh;
        group411193827: THREE.Mesh;
        mesh1914318900: THREE.Mesh;
        mesh1914318900_1: THREE.Mesh;
        mesh1589297463: THREE.Mesh;
        mesh1589297463_1: THREE.Mesh;
        mesh149046089: THREE.Mesh;
        mesh149046089_1: THREE.Mesh;
        mesh209975484: THREE.Mesh;
        mesh209975484_1: THREE.Mesh;
        mesh1367155909: THREE.Mesh;
        mesh1367155909_1: THREE.Mesh;
        mesh368050660: THREE.Mesh;
        mesh368050660_1: THREE.Mesh;
        mesh308963709: THREE.Mesh;
        mesh308963709_1: THREE.Mesh;
        mesh500483907: THREE.Mesh;
        mesh500483907_1: THREE.Mesh;
        mesh748734138: THREE.Mesh;
        mesh748734138_1: THREE.Mesh;
        mesh57321760: THREE.Mesh;
        mesh57321760_1: THREE.Mesh;
        mesh1978860707: THREE.Mesh;
        mesh1978860707_1: THREE.Mesh;
        mesh270291541: THREE.Mesh;
        mesh270291541_1: THREE.Mesh;
        mesh1236667903: THREE.Mesh;
        mesh1236667903_1: THREE.Mesh;
        mesh984249092: THREE.Mesh;
        mesh984249092_1: THREE.Mesh;
        mesh713317902: THREE.Mesh;
        mesh713317902_1: THREE.Mesh;
        mesh592530000: THREE.Mesh;
        mesh592530000_1: THREE.Mesh;
        mesh592530000_2: THREE.Mesh;
        mesh308072958: THREE.Mesh;
        mesh308072958_1: THREE.Mesh;
        mesh1735703239: THREE.Mesh;
        mesh1735703239_1: THREE.Mesh;
        mesh721168951: THREE.Mesh;
        mesh721168951_1: THREE.Mesh;
        mesh608434854: THREE.Mesh;
        mesh608434854_1: THREE.Mesh;
        mesh1862259937: THREE.Mesh;
        mesh1862259937_1: THREE.Mesh;
        mesh334606726: THREE.Mesh;
        mesh334606726_1: THREE.Mesh;
        mesh1161448215: THREE.Mesh;
        mesh1161448215_1: THREE.Mesh;
        mesh380869932: THREE.Mesh;
        mesh380869932_1: THREE.Mesh;
        mesh43143167: THREE.Mesh;
        mesh43143167_1: THREE.Mesh;
        mesh43143167_2: THREE.Mesh;
        mesh1742195230: THREE.Mesh;
        mesh1742195230_1: THREE.Mesh;
        mesh1742195230_2: THREE.Mesh;
        mesh1228860991: THREE.Mesh;
        mesh1228860991_1: THREE.Mesh;
        mesh1228860991_2: THREE.Mesh;
        group678392605: THREE.Mesh;
        group1331905068: THREE.Mesh;
        group228739432: THREE.Mesh;
        mesh672612964: THREE.Mesh;
        mesh672612964_1: THREE.Mesh;
        mesh1609886918: THREE.Mesh;
        mesh1609886918_1: THREE.Mesh;
        group61007489: THREE.Mesh;
        group2146409170: THREE.Mesh;
        mesh1385259583: THREE.Mesh;
        mesh1385259583_1: THREE.Mesh;
        mesh482221332: THREE.Mesh;
        mesh482221332_1: THREE.Mesh;
        mesh1391462193: THREE.Mesh;
        mesh1391462193_1: THREE.Mesh;
        group49840277: THREE.Mesh;
        group106678485: THREE.Mesh;
        group886253053: THREE.Mesh;
        group1628733608: THREE.Mesh;
        group1092607654: THREE.Mesh;
        group91675880: THREE.Mesh;
        group1582874580: THREE.Mesh;
        group1111343245: THREE.Mesh;
        group1502999131: THREE.Mesh;
        group1247129074: THREE.Mesh;
        mesh1703659211: THREE.Mesh;
        mesh1703659211_1: THREE.Mesh;
        group1599426184: THREE.Mesh;
        group980904771: THREE.Mesh;
        group1595824761: THREE.Mesh;
        mesh1563168281: THREE.Mesh;
        mesh1563168281_1: THREE.Mesh;
        mesh1563168281_2: THREE.Mesh;
        mesh610326153: THREE.Mesh;
        mesh610326153_1: THREE.Mesh;
        mesh1379738823: THREE.Mesh;
        mesh1379738823_1: THREE.Mesh;
        mesh2121202750: THREE.Mesh;
        mesh2121202750_1: THREE.Mesh;
        mesh2121202750_2: THREE.Mesh;
        mesh1659243643: THREE.Mesh;
        mesh1659243643_1: THREE.Mesh;
        mesh1115883482: THREE.Mesh;
        mesh1115883482_1: THREE.Mesh;
        mesh1115883482_2: THREE.Mesh;
        mesh2054733290: THREE.Mesh;
        mesh2054733290_1: THREE.Mesh;
        mesh1949868650: THREE.Mesh;
        mesh1949868650_1: THREE.Mesh;
        mesh1518361734: THREE.Mesh;
        mesh1518361734_1: THREE.Mesh;
        mesh976429815: THREE.Mesh;
        mesh976429815_1: THREE.Mesh;
        mesh2001573440: THREE.Mesh;
        mesh2001573440_1: THREE.Mesh;
        mesh751163001: THREE.Mesh;
        mesh751163001_1: THREE.Mesh;
        mesh1786607626: THREE.Mesh;
        mesh1786607626_1: THREE.Mesh;
        mesh1786607626_2: THREE.Mesh;
        mesh622440097: THREE.Mesh;
        mesh622440097_1: THREE.Mesh;
        mesh626518297: THREE.Mesh;
        mesh626518297_1: THREE.Mesh;
        mesh93306127: THREE.Mesh;
        mesh93306127_1: THREE.Mesh;
        mesh1100157397: THREE.Mesh;
        mesh1100157397_1: THREE.Mesh;
        mesh502536234: THREE.Mesh;
        mesh502536234_1: THREE.Mesh;
        mesh523920511: THREE.Mesh;
        mesh523920511_1: THREE.Mesh;
        mesh1831822204: THREE.Mesh;
        mesh1831822204_1: THREE.Mesh;
        mesh299204456: THREE.Mesh;
        mesh299204456_1: THREE.Mesh;
        mesh86763974: THREE.Mesh;
        mesh86763974_1: THREE.Mesh;
        mesh61455779: THREE.Mesh;
        mesh61455779_1: THREE.Mesh;
        mesh1344587834: THREE.Mesh;
        mesh1344587834_1: THREE.Mesh;
        mesh1460322968: THREE.Mesh;
        mesh1460322968_1: THREE.Mesh;
        group750603090: THREE.Mesh;
        group592803558: THREE.Mesh;
        group1492146600: THREE.Mesh;
        group86307362: THREE.Mesh;
        group390084612: THREE.Mesh;
        group591182058: THREE.Mesh;
        group371943742: THREE.Mesh;
        mesh793771576: THREE.Mesh;
        mesh793771576_1: THREE.Mesh;
        group1190091320: THREE.Mesh;
        group863499552: THREE.Mesh;
        group1419798842: THREE.Mesh;
        group1719381167: THREE.Mesh;
        group607067024: THREE.Mesh;
        group1721228000: THREE.Mesh;
        group282759822: THREE.Mesh;
        mesh1846468049: THREE.Mesh;
        mesh1846468049_1: THREE.Mesh;
        mesh643711727: THREE.Mesh;
        mesh643711727_1: THREE.Mesh;
        mesh2084601409: THREE.Mesh;
        mesh2084601409_1: THREE.Mesh;
        group1602713423: THREE.Mesh;
        group465555899: THREE.Mesh;
        group1550195885: THREE.Mesh;
        group197357736: THREE.Mesh;
        group1334676454: THREE.Mesh;
        group1802490509: THREE.Mesh;
        mesh1431825437: THREE.Mesh;
        mesh1431825437_1: THREE.Mesh;
        mesh1431825437_2: THREE.Mesh;
        mesh918087620: THREE.Mesh;
        mesh918087620_1: THREE.Mesh;
        mesh918087620_2: THREE.Mesh;
        mesh918087620_3: THREE.Mesh;
        group970021958: THREE.Mesh;
        mesh700178591: THREE.Mesh;
        mesh700178591_1: THREE.Mesh;
        mesh831255012: THREE.Mesh;
        mesh831255012_1: THREE.Mesh;
        group1688249756: THREE.Mesh;
        group1622299973: THREE.Mesh;
        group1350700828: THREE.Mesh;
        group751619568: THREE.Mesh;
        group2019320654: THREE.Mesh;
        group220399284: THREE.Mesh;
        group1407108972: THREE.Mesh;
        group1198249082: THREE.Mesh;
        group160437510: THREE.Mesh;
        group1205835326: THREE.Mesh;
    };
    materials: {
        mat10: THREE.MeshStandardMaterial;
        mat22: THREE.MeshStandardMaterial;
        mat11: THREE.MeshStandardMaterial;
        mat21: THREE.MeshStandardMaterial;
        mat5: THREE.MeshStandardMaterial;
        mat20: THREE.MeshStandardMaterial;
        mat17: THREE.MeshStandardMaterial;
        mat15: THREE.MeshStandardMaterial;
        mat3: THREE.MeshStandardMaterial;
        mat12: THREE.MeshStandardMaterial;
        mat18: THREE.MeshStandardMaterial;
        mat19: THREE.MeshStandardMaterial;
        mat9: THREE.MeshStandardMaterial;
        mat4: THREE.MeshStandardMaterial;
        mat16: THREE.MeshStandardMaterial;
        mat13: THREE.MeshStandardMaterial;
        mat23: THREE.MeshStandardMaterial;
    };
};

export function Vancouver(props: JSX.IntrinsicElements['group']) {
    const { nodes, materials } = useGLTF('/objects/vancouver.glb') as GLTFResult;
    const stencil = useMask(1, false);

    return (
        <group {...props} dispose={null}>
            <mesh castShadow receiveShadow geometry={nodes.group1452617283.geometry} {...stencil}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1926126815.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group269306622.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group62122215.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group462179313.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group32002972.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1859955707.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1600036614.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group758244815.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1297686250.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group81821235.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group840029287.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group644830360.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group264317860.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group740519829.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1921196339.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group960292067.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group664967870.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group693226123.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group394304578.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group2072235421.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group381120477.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group305972276.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group411193827.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group678392605.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1331905068.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group228739432.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group61007489.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group2146409170.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group49840277.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group106678485.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group886253053.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1628733608.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1092607654.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group91675880.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1582874580.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1111343245.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1502999131.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1247129074.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1599426184.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group980904771.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1595824761.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group750603090.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group592803558.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1492146600.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group86307362.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group390084612.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group591182058.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group371943742.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1190091320.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group863499552.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1419798842.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1719381167.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group607067024.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1721228000.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group282759822.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1602713423.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group465555899.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1550195885.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group197357736.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1334676454.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1802490509.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group970021958.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1688249756.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1622299973.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1350700828.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group751619568.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group2019320654.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group220399284.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1407108972.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1198249082.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group160437510.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.group1205835326.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1656777012.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1656777012_1.geometry}>
                <meshBasicMaterial {...materials.mat22} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1971097135.geometry}>
                <meshBasicMaterial {...materials.mat11} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1971097135_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh944212785.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh944212785_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh441989801.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh441989801_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1533200203.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1533200203_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2085297235.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2085297235_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh634886533.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh634886533_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1023818653.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1023818653_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1895269739.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1895269739_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh486381022.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh486381022_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh13433428.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh13433428_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh747770077.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh747770077_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1755377577.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1755377577_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1148249337.geometry}>
                <meshBasicMaterial {...materials.mat15} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1148249337_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1914318900.geometry}>
                <meshBasicMaterial {...materials.mat3} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1914318900_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1589297463.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1589297463_1.geometry}>
                <meshBasicMaterial {...materials.mat3} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh149046089.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh149046089_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh209975484.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh209975484_1.geometry}>
                <meshBasicMaterial {...materials.mat12} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1367155909.geometry}>
                <meshBasicMaterial {...materials.mat18} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1367155909_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh368050660.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh368050660_1.geometry}>
                <meshBasicMaterial {...materials.mat19} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh308963709.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh308963709_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh500483907.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh500483907_1.geometry}>
                <meshBasicMaterial {...materials.mat9} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh748734138.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh748734138_1.geometry}>
                <meshBasicMaterial {...materials.mat18} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh57321760.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh57321760_1.geometry}>
                <meshBasicMaterial {...materials.mat9} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1978860707.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1978860707_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh270291541.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh270291541_1.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1236667903.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1236667903_1.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh984249092.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh984249092_1.geometry}>
                <meshBasicMaterial {...materials.mat16} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh713317902.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh713317902_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh592530000.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh592530000_1.geometry}>
                <meshBasicMaterial {...materials.mat3} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh592530000_2.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh308072958.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh308072958_1.geometry}>
                <meshBasicMaterial {...materials.mat16} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1735703239.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1735703239_1.geometry}>
                <meshBasicMaterial {...materials.mat16} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh721168951.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh721168951_1.geometry}>
                <meshBasicMaterial {...materials.mat13} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh608434854.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh608434854_1.geometry}>
                <meshBasicMaterial {...materials.mat13} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1862259937.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1862259937_1.geometry}>
                <meshBasicMaterial {...materials.mat13} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh334606726.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh334606726_1.geometry}>
                <meshBasicMaterial {...materials.mat19} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1161448215.geometry}>
                <meshBasicMaterial {...materials.mat19} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1161448215_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh380869932.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh380869932_1.geometry}>
                <meshBasicMaterial {...materials.mat22} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh43143167.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh43143167_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh43143167_2.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1742195230.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1742195230_1.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1742195230_2.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1228860991.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1228860991_1.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1228860991_2.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh672612964.geometry}>
                <meshBasicMaterial {...materials.mat12} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh672612964_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1609886918.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1609886918_1.geometry}>
                <meshBasicMaterial {...materials.mat18} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1385259583.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1385259583_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh482221332.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh482221332_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1391462193.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1391462193_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1703659211.geometry}>
                <meshBasicMaterial {...materials.mat18} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1703659211_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1563168281.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1563168281_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1563168281_2.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh610326153.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh610326153_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1379738823.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1379738823_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2121202750.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2121202750_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2121202750_2.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1659243643.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1659243643_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1115883482.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1115883482_1.geometry}>
                <meshBasicMaterial {...materials.mat3} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1115883482_2.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2054733290.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2054733290_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1949868650.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1949868650_1.geometry}>
                <meshBasicMaterial {...materials.mat22} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1518361734.geometry}>
                <meshBasicMaterial {...materials.mat22} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1518361734_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh976429815.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh976429815_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2001573440.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2001573440_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh751163001.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh751163001_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1786607626.geometry}>
                <meshBasicMaterial {...materials.mat15} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1786607626_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1786607626_2.geometry}>
                <meshBasicMaterial {...materials.mat16} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh622440097.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh622440097_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh626518297.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh626518297_1.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh93306127.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh93306127_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1100157397.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1100157397_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh502536234.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh502536234_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh523920511.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh523920511_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1831822204.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1831822204_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh299204456.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh299204456_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh86763974.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh86763974_1.geometry}>
                <meshBasicMaterial {...materials.mat5} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh61455779.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh61455779_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1344587834.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1344587834_1.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1460322968.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1460322968_1.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh793771576.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh793771576_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1846468049.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1846468049_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh643711727.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh643711727_1.geometry}>
                <meshBasicMaterial {...materials.mat10} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2084601409.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh2084601409_1.geometry}>
                <meshBasicMaterial {...materials.mat4} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1431825437.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1431825437_1.geometry}>
                <meshBasicMaterial {...materials.mat20} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh1431825437_2.geometry}>
                <meshBasicMaterial {...materials.mat22} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh918087620.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh918087620_1.geometry}>
                <meshBasicMaterial {...materials.mat16} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh918087620_2.geometry}>
                <meshBasicMaterial {...materials.mat22} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh918087620_3.geometry}>
                <meshBasicMaterial {...materials.mat23} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh700178591.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh700178591_1.geometry}>
                <meshBasicMaterial {...materials.mat21} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh831255012.geometry}>
                <meshBasicMaterial {...materials.mat15} {...stencil} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.mesh831255012_1.geometry}>
                <meshBasicMaterial {...materials.mat17} {...stencil} />
            </mesh>
        </group>
    );
}

useGLTF.preload('/objects/vancouver.glb');
