import { Euler, useThree } from '@react-three/fiber';
import { Float, Image, RoundedBox, Text } from '@react-three/drei';
import { DragablePowerBank } from './DragablePowerBank';
import { PowerBank } from './PowerBank';
import { useState } from 'react';
import { Vector3 } from 'three';

type PowerBankType = {
    index: number;
    initialPosition: Vector3;
    rotation: Euler;
    scale: number;
    isFound: boolean;
};

export function Module() {
    const { width, height } = useThree((state) => state.viewport);
    const initialPowerBanks: PowerBankType[] = [
        { index: 0, initialPosition: new Vector3(0, 0, 0), rotation: [0, 0, 0], scale: 0.6, isFound: true },
        { index: 1, initialPosition: new Vector3(0, 0, 0), rotation: [0, 0, 0], scale: 0.6, isFound: true },

        {
            index: 2,
            initialPosition: new Vector3(width * 0.3, -height * 1.1, -1),
            rotation: [-Math.PI / 2 + 0.4, 0, -Math.PI / 2],
            scale: 0.6,
            isFound: false,
        },
        {
            index: 3,
            initialPosition: new Vector3(width * 0.2, -height * 0.35, -4),
            rotation: [0, 0.2, 0],
            scale: 1,
            isFound: false,
        },
        {
            index: 4,
            initialPosition: new Vector3(-width * 0.4, -height * 0.8, -3),
            rotation: [0.4, 1, 0],
            scale: 0.6,
            isFound: false,
        },
        {
            index: 5,
            initialPosition: new Vector3(width * 0.4, height * 0.5, -2),
            rotation: [0.4, -1, 0],
            scale: 0.4,
            isFound: false,
        },
    ];

    const [powerBanks, setPowerBanks] = useState<PowerBankType[]>(initialPowerBanks);

    const powerBankWidth = width * 0.3;
    const powerBankHeight = powerBankWidth * 0.6;
    const powerBankDepth = powerBankWidth * 0.5;

    const modulePosition: Vector3 = new Vector3(-5, -2, -10);

    function onDragEnd({ position, index }: { position: Vector3; index: number }) {
        const powerBank = powerBanks[index];

        const positionX = position.x + powerBank.initialPosition.x;
        const positionY = position.y + powerBank.initialPosition.y;

        if (
            Math.abs(positionX - modulePosition.x) < powerBankWidth / 2 &&
            Math.abs(positionY - modulePosition.y) < powerBankHeight / 2
        ) {
            powerBanks[index].isFound = true;
            setPowerBanks([...powerBanks]);
        }
    }

    const areAllPowerBanksFound = powerBanks.every(({ isFound }) => isFound);

    return (
        <group>
            <group dispose={null} position={modulePosition} rotation-x={0.2}>
                <Float
                    speed={3} // Animation speed, defaults to 1
                    rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
                    floatIntensity={0.5} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                >
                    <RoundedBox
                        args={[powerBankWidth, powerBankHeight, powerBankDepth]} // Width, height, depth. Default is [1, 1, 1]
                        radius={0.1} // Radius of the rounded corners. Default is 0.05
                        smoothness={10} // The number of curve segments. Default is 4
                        bevelSegments={10} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                        creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
                    >
                        <meshStandardMaterial color="#090909" toneMapped={false} />
                    </RoundedBox>
                    <group position-z={powerBankDepth / 2 + 0.01}>
                        <Image
                            position-y={powerBankHeight * 0}
                            url={'/images/oneflash/logo-transparent.png'}
                            transparent
                            scale={[powerBankHeight / 3, powerBankHeight / 3]}
                        />
                        <Text
                            anchorX="center"
                            anchorY="middle"
                            fontSize={0.2}
                            fontWeight={700}
                            letterSpacing={-0.02}
                            textAlign="center"
                            lineHeight={1}
                            maxWidth={powerBankWidth * 0.8}
                            position-y={-powerBankHeight * 0.3}
                        >
                            RETURN THE POWER BANKS HERE
                            <meshBasicMaterial color={'#ef4270'} toneMapped={false} />
                        </Text>

                        {powerBanks.map(({ isFound }, index) => (
                            <group
                                position-x={
                                    ((index - powerBanks.length / 2) * (powerBankWidth * 0.9)) / powerBanks.length +
                                    (powerBankWidth * 0.9) / powerBanks.length / 2
                                }
                                key={index}
                            >
                                <mesh position-y={powerBankHeight * 0.35}>
                                    <planeGeometry args={[0.2, 0.2]} />
                                    <meshBasicMaterial
                                        color={areAllPowerBanksFound ? '#e600ff' : isFound ? '#0091ff' : '#001626'}
                                    />
                                </mesh>
                                {isFound ? (
                                    <group
                                        position-y={powerBankHeight / 2}
                                        position-z={-powerBankDepth / 2}
                                        rotation-x={0}
                                        rotation-y={Math.PI / 2}
                                        scale={0.6}
                                    >
                                        <PowerBank />
                                    </group>
                                ) : null}
                            </group>
                        ))}
                    </group>
                </Float>
            </group>
            <group>
                {powerBanks
                    .filter(({ isFound }) => !isFound)
                    .map(({ index, initialPosition, rotation, scale }) => (
                        <DragablePowerBank
                            key={index}
                            position={initialPosition}
                            rotation={rotation}
                            scale={scale}
                            onDragEnd={(position: Vector3) => onDragEnd({ position, index })}
                        />
                    ))}
            </group>
        </group>
    );
}
