import { useFrame } from '@react-three/fiber';
import { Group, MathUtils } from 'three';
import { useEffect, useRef } from 'react';

export function BluetoothWave({
    timestamp,
    startPosition,
    distance,
    width,
    delay,
    onDelete,
}: {
    timestamp: number;
    startPosition: number;
    distance: number;
    width: number;
    delay: number;
    onDelete: () => void;
}) {
    const groupRef = useRef<Group>(null);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onDelete();
        }, delay * 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useFrame((_, delta) => {
        if (!groupRef.current) {
            return;
        }

        const deltaY = (((new Date().getTime() - timestamp) / 1000) * distance) / delay;

        groupRef.current.position.y = MathUtils.damp(groupRef.current.position.y, deltaY, 4, delta);
    });

    return (
        <group ref={groupRef}>
            <mesh position-y={startPosition}>
                <sphereGeometry args={[width, 8, 8]} />
                <meshStandardMaterial color="#ffffff" toneMapped={false} />
            </mesh>
        </group>
    );
}
