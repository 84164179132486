import { Canvas } from '@react-three/fiber';
import styled from 'styled-components';

import { Environment, Loader, Scroll, ScrollControls } from '@react-three/drei';
import { Home3DFixed } from './Home3DFixed';
import { Home3DScroll } from './Home3DScroll';
import { HomeHTMLScroll } from './HomeHTMLScroll';
import { HomeHTMLFixed } from './HomeHTMLFixed';
import { Suspense } from 'react';

export const Home = () => {
    return (
        <Container>
            <Canvas camera={{ position: [0, -3.2, 40], fov: 12 }} gl={{ stencil: true }}>
                <color args={['#090611']} attach="background" />
                <Environment preset="city" />
                <hemisphereLight position={[0, 0, 1]} intensity={2} />

                <Suspense>
                    <ScrollControls pages={15} damping={0.1} maxSpeed={0.5}>
                        <Home3DFixed />

                        <Scroll>
                            <Home3DScroll />
                        </Scroll>
                        <Scroll html>
                            <HomeHTMLScroll />
                        </Scroll>
                        <HomeHTMLFixed />
                    </ScrollControls>
                </Suspense>
            </Canvas>
            <Loader
                dataInterpolation={() => 'Loading Experience'}
                containerStyles={{ backgroundColor: '#090611', fontFamily: 'Arial', fontSize: 30 }}
                innerStyles={{
                    width: '50vw',
                    height: 5,
                    borderRadius: 5,
                }}
                barStyles={{ borderRadius: 5 }}
            />
            {/* <LoadingScreen /> */}
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
`;
