import { Image } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { DoubleSide, Group, MathUtils } from 'three';

export const BadgeImage = ({
    imageUrl,
    imageIndex,
    imageWidth,
    selectedIndex,
    setSelectedIndex,
}: {
    imageUrl: string;
    imageIndex: number;
    imageWidth: number;
    selectedIndex: number | null;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
    const imageRef = useRef<Group>(null);

    useFrame((_, delta) => {
        if (!imageRef.current) {
            return;
        }
        const isSelected = selectedIndex === imageIndex;

        imageRef.current.scale.x = MathUtils.damp(imageRef.current.scale.x, isSelected ? 1.5 : 1, 4, delta);
        imageRef.current.scale.y = MathUtils.damp(imageRef.current.scale.y, isSelected ? 1.5 : 1, 4, delta);
        imageRef.current.position.z = MathUtils.damp(imageRef.current.position.z, isSelected ? 1 : 0, 4, delta);
    });

    return (
        <group
            ref={imageRef}
            onPointerEnter={() => {
                setSelectedIndex(imageIndex);
            }}
            onPointerLeave={() => {
                if (selectedIndex === imageIndex) {
                    setSelectedIndex(null);
                }
            }}
        >
            <Image url={imageUrl} transparent scale={[imageWidth, imageWidth]} side={DoubleSide} toneMapped={false} />
        </group>
    );
};
