/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { type GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
    nodes: {
        bag: THREE.Mesh;
    };
    materials: {
        brownLight: THREE.MeshStandardMaterial;
    };
};

export default function Bag(props: JSX.IntrinsicElements['group']) {
    const { nodes, materials } = useGLTF('/objects/bag.gltf') as GLTFResult;
    return (
        <group {...props} dispose={null}>
            <mesh geometry={nodes.bag.geometry} material={materials.brownLight} scale={0.71} />
        </group>
    );
}

useGLTF.preload('/objects/bag.gltf');
