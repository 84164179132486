import { Float, Text } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import Fridge from '../models/Fridge';
import { useRef } from 'react';
import { Group, MathUtils } from 'three';
import { ImageGallery } from './ImageGallery';
import { Highlights } from './Highlights';

const highlights = [
    {
        description:
            'Totem has two products. The first is a product delivery service. The second is an office store with connected fridges.\n' +
            '\n' +
            'For the first service, a dedicated website allows customers to select the products to be delivered each week (fruit, snacks, drinks, etc.).\n' +
            '\n' +
            'For the office store, Totem autonomously manages the products made available to your employees. They can pick up their lunch from our connected fridges, or buy extra food when they run out of toothpaste or washing up liquid.\n' +
            '\n' +
            'Roll over the badges on the left to find out more.\n',
        imageUrl: '/images/badges/project.png',
        title: 'Project',
    },
    {
        description:
            "During our adventure at Totem, we each set up a different team: one to work on the mobile application for our users, the other on the company's internal tools.\n" +
            '\n' +
            'Each two-person team worked closely with the business. The first team worked with a designer, a PO and the marketing department. The second team worked with the Operations Manager, the Preparers and the CSM.\n' +
            '\n' +
            'When we arrived at Totem, we set up an agile process to work with the business: organising tasks in Asana, productivity meetings and week-long sprints.\n' +
            '\n' +
            'We also increased collaboration between developers by emphasising PR reviews and peer programming.',
        imageUrl: '/images/badges/team.png',
        title: 'Team Management',
    },
    {
        description:
            'The team responsible for the mobile application has added elements that are attractive to users.\n' +
            '\n' +
            'They started by adding daily offers, highlighting products that were nearing their expiry date.\n' +
            '\n' +
            'Then they added promotions based on time of day and user preferences.\n' +
            '\n' +
            'Finally, the Totanimals project was born. This is a gamified loyalty card. Every purchase earns gems that can be exchanged for cash.',
        imageUrl: '/images/badges/gift.png',
        title: 'Loyalty Program',
    },
    {
        description:
            'The team responsible for internal tools has increased sourcing from suppliers and shipping to customers.\n' +
            '\n' +
            'An administration area was created to manage stock and customer stock. Then to manage orders. Thanks to the data team, sales forecasts were used to automate the creation and dispatch of orders.\n' +
            '\n' +
            'At the same time, an application was created to help prepare customer orders and store fridges on site.\n' +
            '\n' +
            'Only one person was needed to manage the different orders (in addition to the pickers and delivery drivers).\n',
        imageUrl: '/images/badges/scale.png',
        title: 'Scalability',
    },
];

export const ProjectTotem = () => {
    const { width, height } = useThree((state) => state.viewport);

    const fridgeRef = useRef<Group>(null);

    useFrame(({ pointer }, delta) => {
        if (!fridgeRef.current) {
            return;
        }

        fridgeRef.current.rotation.y = MathUtils.damp(fridgeRef.current.rotation.y, pointer.x / 2 - 0.25, 4, delta);
    });

    return (
        <group>
            <group position-x={-width / 2 + width / 30}>
                <group>
                    <Text
                        anchorX="left"
                        fontSize={0.5}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="left"
                        lineHeight={1}
                    >
                        Totem - More than a connected fridge
                        <meshBasicMaterial color={'white'} toneMapped={false} />
                    </Text>
                </group>

                <Highlights isRight={false} highlights={highlights} />

                <ImageGallery
                    imageUrls={[
                        '/images/totem/1.jpeg',
                        '/images/totem/2.jpeg',
                        '/images/totem/3.jpeg',
                        '/images/totem/4.jpeg',
                        '/images/totem/5.jpeg',
                        '/images/totem/6.jpeg',
                    ]}
                    isRight={false}
                />
            </group>

            <group ref={fridgeRef} position={[width / 2.5, -height / 2.5, -5]} rotation={[0, -0.5, 0]}>
                <Float
                    speed={1} // Animation speed, defaults to 1
                    rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
                    floatIntensity={0.5} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                >
                    <Fridge />
                </Float>
            </group>
        </group>
    );
};
