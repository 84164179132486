import { useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useState } from 'react';
import { ImageGalleryImage } from './ImageGalleryImage';
import { PowerBank } from '../models/powerBank/PowerBank';

const mobileAspectRatio = 2048 / 946;
const desktopAspectRatio = 975 / 1792;

export const ImageGallery = ({
    imageUrls,
    isRight,
    isDesktop,
}: {
    imageUrls: string[];
    isRight: boolean;
    isDesktop?: boolean;
}) => {
    const { width, height } = useThree((state) => state.viewport);

    const aspectRatio = isDesktop ? desktopAspectRatio : mobileAspectRatio;

    const images = useTexture(imageUrls);
    const imageWidth = isDesktop ? width / 6 : width / 15;
    const imageHeight = imageWidth * aspectRatio;

    const gap = width / 30;

    const [selectedIndex, setSelectedIndex] = useState<null | number>(null);

    // position-x={(isRight ? -1 : 1) * imageWidth * 0.5}
    return (
        <group position-y={-0.9 * height}>
            <group position-x={isRight ? -imageUrls.length * (imageWidth + gap) - gap : 0} position-y={imageHeight / 2}>
                {images.map((image, index) => {
                    return (
                        <group key={index} position={[index * (imageWidth + gap) + gap + imageWidth / 2, 0, 0]}>
                            <ImageGalleryImage
                                aspectRatio={aspectRatio}
                                isRight={isRight}
                                isDesktop={isDesktop}
                                image={image}
                                imageIndex={index}
                                numberOfImages={imageUrls.length}
                                imageWidth={imageWidth}
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                            />
                        </group>
                    );
                })}
            </group>
        </group>
    );
};
