import { useThree } from '@react-three/fiber';
import { Image, RoundedBox, Text } from '@react-three/drei';

export function PowerBank() {
    const { height } = useThree((state) => state.viewport);

    const powerBankHeight = height * 0.3;
    const powerBankWidth = powerBankHeight * 0.5;
    const powerBankDepth = powerBankHeight * 0.1;

    return (
        <group>
            <RoundedBox
                args={[powerBankWidth, powerBankHeight, powerBankDepth]} // Width, height, depth. Default is [1, 1, 1]
                radius={0.1} // Radius of the rounded corners. Default is 0.05
                smoothness={10} // The number of curve segments. Default is 4
                bevelSegments={10} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
            >
                <meshStandardMaterial color="#f3ea5d" toneMapped={false} />
            </RoundedBox>
            <group position-z={powerBankDepth / 2 + 0.01}>
                <Image
                    position-y={powerBankHeight / 6}
                    url={'/images/oneflash/logo-transparent.png'}
                    transparent
                    scale={[1, 1]}
                />
                <Text
                    anchorX="center"
                    anchorY="middle"
                    fontSize={0.2}
                    fontWeight={700}
                    letterSpacing={-0.02}
                    textAlign="center"
                    lineHeight={1}
                    maxWidth={powerBankWidth * 0.8}
                    position-y={-powerBankHeight / 4}
                >
                    CHARGE YOUR PHONE
                    <meshBasicMaterial color={'#ef4270'} toneMapped={false} />
                </Text>
            </group>
        </group>
    );
}
