import { Html, useScroll } from '@react-three/drei';
import { useEffect, useRef } from 'react';

export const HomeHTMLFixed = () => {
    const scrollData = useScroll();
    const containerRef = useRef(document.createElement('div'));

    useEffect(() => {
        setTimeout(() => {
            scrollData.fixed.appendChild(containerRef.current);
        }, 10);
    }, []);

    return (
        <Html portal={{ current: containerRef.current }}>
            <div />
        </Html>
    );
};
