import { useThree } from '@react-three/fiber';
import { BadgeImage } from './projects/BadgeImage';
import { useState } from 'react';
import { Center, Float, Text } from '@react-three/drei';

// Skia, MQTT,

const badges = [
    {
        imageUrl: '/images/badges/react.png',
        title: 'React',
    },
    {
        imageUrl: '/images/badges/expo.png',
        title: 'Expo (React Native)',
    },
    {
        imageUrl: '/images/badges/node.png',
        title: 'Node.js',
    },
    {
        imageUrl: '/images/badges/graphql.png',
        title: 'GraphQL (Apollo)',
    },
    {
        imageUrl: '/images/badges/mongo.png',
        title: 'MongoDB',
    },
    {
        imageUrl: '/images/badges/kubernetes.png',
        title: 'Kubernetes',
    },
];

const aspectRatio = 1;

export const OurStack = () => {
    const { width, height } = useThree((state) => state.viewport);

    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const imageWidth = width / 10;
    const imageHeight = imageWidth * aspectRatio;

    return (
        <group>
            <group>
                <Center>
                    {badges.map(({ imageUrl }, index) => {
                        return (
                            <group key={index} position-x={index * 1.2 * imageWidth}>
                                <Float
                                    speed={3} // Animation speed, defaults to 1
                                    rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
                                    floatIntensity={0.8} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                                    floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                                >
                                    <BadgeImage
                                        imageUrl={imageUrl}
                                        imageIndex={index}
                                        imageWidth={imageWidth}
                                        selectedIndex={selectedIndex}
                                        setSelectedIndex={setSelectedIndex}
                                    />
                                </Float>
                            </group>
                        );
                    })}
                </Center>
                <Float
                    speed={3} // Animation speed, defaults to 1
                    rotationIntensity={0.2} // XYZ rotation intensity, defaults to 1
                    floatIntensity={0.5} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                >
                    <group position-y={-imageHeight}>
                        <Text
                            anchorX={'center'}
                            anchorY={'top'}
                            textAlign={'center'}
                            fontSize={0.3}
                            fontWeight={700}
                            letterSpacing={-0.02}
                            lineHeight={1.4}
                            maxWidth={width * 0.8}
                            outlineColor={'#000000'}
                            outlineOffsetX={'5%'}
                            outlineOffsetY={'5%'}
                        >
                            {selectedIndex !== null ? badges?.[selectedIndex].title : 'Hover the badges'}
                            <meshBasicMaterial
                                color={selectedIndex !== null ? '#ffffff' : '#999999'}
                                toneMapped={false}
                            />
                        </Text>
                    </group>
                </Float>
            </group>
        </group>
    );
};
