import { Text } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { ImageGallery } from './ImageGallery';
import { Highlights } from './Highlights';
import { Bluetooth } from '../models/bluetooth/Bluetooth';

const highlights = [
    {
        description:
            'The "Secret" Project aims to make it easier for users to understand their "data" (for reasons of secrecy, we have mocked up the design and will not go into detail).\n' +
            '\n' +
            'To achieve this, we developed a mobile application for iOS and Android operating systems that displays real-time data collected by Bluetooth (BLE) devices.\n' +
            '\n' +
            'We worked closely with business managers and designers, which enabled us to iterate quickly and remain pragmatic in the creation of this MVP.\n' +
            '\n' +
            'To find out more, hover over the badges on the left.\n',
        imageUrl: '/images/badges/project.png',
        title: 'Project',
    },
    {
        description:
            'From the mobile application, we needed to connect to devices using the BLE (Bluetooth Low Energy) protocol.\n' +
            '\n' +
            'The different phases to be managed were: searching for nearby devices, displaying available objects, pairing, collecting data and disconnecting.\n' +
            '\n' +
            'We used Expo (React Native) to develop the application and the “react-native-ble-plx” library to manage the Bluetooth connection.\n',
        imageUrl: '/images/badges/bluetooth.png',
        title: 'Bluetooth',
    },
    {
        description:
            "Once the data had been collected and transformed, we presented it in the form of a custom graphic representing a car's speedometer.\n" +
            '\n' +
            'The graphics were created entirely by hand using React Native Skia, a 2D graphics library.\n' +
            '\n' +
            "What's more, the graphics adapt to the size of the screen to ensure optimal ergonomics even on very small screens.\n",
        imageUrl: '/images/badges/skia.png',
        title: 'React Native Skia',
    },
];

export const ProjectNaviwatt = () => {
    const { width } = useThree((state) => state.viewport);

    return (
        <group>
            <group position-x={-width / 2 + width / 30}>
                <group>
                    <Text
                        anchorX="left"
                        fontSize={0.5}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="left"
                        lineHeight={1}
                    >
                        "Secret" Project - Analyse "some" data
                        <meshBasicMaterial color={'white'} toneMapped={false} />
                    </Text>
                </group>

                <Highlights isRight={false} highlights={highlights} />

                <ImageGallery
                    imageUrls={['/images/naviwatt/1.png', '/images/naviwatt/2.png', '/images/naviwatt/3.png']}
                    isRight={false}
                />
            </group>
            <Bluetooth />
        </group>
    );
};
