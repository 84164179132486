/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { forwardRef } from 'react';
import { GroupProps } from '@react-three/fiber';
import { type GLTF } from 'three-stdlib';
import { useGLTF, useTexture } from '@react-three/drei';

type M1GLTFResult = GLTF & {
    nodes: {
        back_1: THREE.Mesh;
        back_2: THREE.Mesh;
        matte: THREE.Mesh;
        body_1: THREE.Mesh;
        body_2: THREE.Mesh;
    };
    materials: {
        blackmatte: THREE.MeshStandardMaterial;
        aluminium: THREE.MeshStandardMaterial;
    };
};

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: akshatmittal (https://sketchfab.com/akshatmittal)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/2021-macbook-pro-14-m1-pro-m1-max-f6b0b940fb6a4286b18a674ef32af2d3
title: 2021 Macbook Pro 14" (M1 Pro / M1 Max)
*/
export const Macbook1 = forwardRef<THREE.Group, GroupProps>(({ ...props }: GroupProps, ref) => {
    const { nodes, materials } = useGLTF('/objects/macbook.glb') as M1GLTFResult;
    const textureRed = useTexture('/objects/macbook-screen-dark.png');

    return (
        <group {...props} dispose={null}>
            <group ref={ref} position={[0, -0.43, -11.35]} rotation={[Math.PI, 0, 0]}>
                <mesh geometry={nodes.back_1.geometry} material={materials.blackmatte} />
                <mesh receiveShadow castShadow geometry={nodes.back_2.geometry} material={materials.aluminium} />
                <mesh geometry={nodes.matte.geometry}>
                    <meshBasicMaterial map={textureRed} toneMapped={false} />
                </mesh>
            </group>
            <mesh
                geometry={nodes.body_1.geometry}
                material={materials.aluminium}
                material-color="#aaaaaf"
                material-envMapIntensity={0.2}
            />
            <mesh geometry={nodes.body_2.geometry} material={materials.blackmatte} />
        </group>
    );
});

useGLTF.preload('/objects/macbook.glb');
useTexture.preload('/objects/macbook-screen-dark.png');
