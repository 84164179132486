/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { type GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
    nodes: {
        Mesh_sodaCan: THREE.Mesh;
        Mesh_sodaCan_1: THREE.Mesh;
        Mesh_sodaCan_2: THREE.Mesh;
        Mesh_sodaCan_3: THREE.Mesh;
    };
    materials: {
        greyDark: THREE.MeshStandardMaterial;
        greyLight: THREE.MeshStandardMaterial;
        red: THREE.MeshStandardMaterial;
        brownDarkest: THREE.MeshStandardMaterial;
    };
};

export default function SodaCan(props: JSX.IntrinsicElements['group'] & { color?: string }) {
    const { nodes, materials } = useGLTF('/objects/soda.gltf') as GLTFResult;
    return (
        <group {...props} dispose={null}>
            <group rotation={[-Math.PI, 0, -Math.PI]}>
                <mesh geometry={nodes.Mesh_sodaCan.geometry} material={materials.greyDark} />
                <mesh geometry={nodes.Mesh_sodaCan_1.geometry} material={materials.greyLight} />
                <mesh geometry={nodes.Mesh_sodaCan_2.geometry}>
                    <meshStandardMaterial color={props.color ?? '#e14d4d'} />
                </mesh>
                <mesh geometry={nodes.Mesh_sodaCan_3.geometry} material={materials.brownDarkest} />
            </group>
        </group>
    );
}

useGLTF.preload('/objects/soda.gltf');
