import styled from 'styled-components';
import { BouncingLetter } from '../letterEffect/BouncingLetter';

export const OurProjects = () => {
    return (
        <Container>
            <TitleContainer>
                <Title>
                    <BouncingLetter letter={'O'} index={1} />
                    <BouncingLetter letter={'U'} index={2} />
                    <BouncingLetter letter={'R'} index={3} />
                    <div style={{ width: 20 }} />
                    <BouncingLetter letter={'P'} index={4} />
                    <BouncingLetter letter={'R'} index={5} />
                    <BouncingLetter letter={'O'} index={6} />
                    <BouncingLetter letter={'J'} index={7} />
                    <BouncingLetter letter={'E'} index={8} />
                    <BouncingLetter letter={'C'} index={9} />
                    <BouncingLetter letter={'T'} index={10} />
                    <BouncingLetter letter={'S'} index={11} />
                </Title>
            </TitleContainer>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
`;
