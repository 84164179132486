import { useThree } from '@react-three/fiber';
import { Particles } from './basicComponents/Particles';
import { boxGeometry, RotatingObject, tetrahedronGeometry } from './basicComponents/RotatingObject';
export const FloatingObjects = () => {
    const { width, height } = useThree((state) => state.viewport);

    return (
        <group>
            <Particles />
            <group position={[-width / 2, -height, -10]}>
                <RotatingObject geometry={boxGeometry} />
            </group>
            {/* <group position={[(2 / 5) * width, -2 * height, -5]}>
                <RotatingObject geometry={icosahedronGeometry} />
            </group> */}
            <group position={[-width / 15, -3 * height, 3]}>
                <RotatingObject geometry={tetrahedronGeometry} />
            </group>
            {/* <group position={[0, -6.3 * height, 0]}>
                {[...new Array(300)].map((_, index) => {
                    return <RotatingBeltObject key={index} height={2 * height} width={2 * width} />;
                })}
            </group> */}
            {[...new Array(15)].map((_, indexA) => {
                return (
                    <group key={indexA} position={[0, -indexA * height, 0]}>
                        {[...new Array(2)].map((_, index) => {
                            return (
                                <group
                                    key={index}
                                    position-x={(Math.random() - 0.5) * width * 1.3}
                                    position-y={-Math.random() * 1 * height - 1 * height}
                                    position-z={-(Math.random() * 10 + 15)}
                                    scale={Math.random() / 2 + 0.5}
                                >
                                    <RotatingObject key={index} />
                                </group>
                            );
                        })}
                    </group>
                );
            })}
        </group>
    );
};
