import { Float, Text } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import TargetStand from './models/TargetStand';
import Book from './models/Book';
import SpaceShip from './models/SpaceShip';

export const OurValues = () => {
    const { width, height } = useThree((state) => state.viewport);

    return (
        <group>
            <group position={[-width / 5, 0, 0]}>
                <Float
                    speed={2} // Animation speed, defaults to 1
                    rotationIntensity={1} // XYZ rotation intensity, defaults to 1
                    floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[-0.2, 0]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                >
                    <group scale={0.5} position-y={-0.3}>
                        <TargetStand />
                    </group>
                </Float>

                <group position={[0, -0.1 * height, 0.1]}>
                    <Text
                        fontSize={0.5}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        outlineColor={'#820d0d'}
                        outlineOffsetX={'5%'}
                        outlineOffsetY={'5%'}
                    >
                        Pragmatism
                        <meshBasicMaterial color={'white'} toneMapped={false} />
                    </Text>
                    <group position={[0, -0.07 * height, 0]}>
                        <Text
                            fontSize={0.18}
                            fontWeight={700}
                            letterSpacing={-0.02}
                            textAlign="center"
                            lineHeight={1.1}
                        >
                            We develop efficiently without over engineering.{'\n'}
                            We aim to create complex system without unecessary complex code.
                            <meshBasicMaterial color={'white'} toneMapped={false} />
                        </Text>
                    </group>
                </group>
            </group>

            <group position={[width / 5, 0, 0]}>
                <Float
                    speed={2} // Animation speed, defaults to 1
                    rotationIntensity={1} // XYZ rotation intensity, defaults to 1
                    floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[0, 0.2]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                >
                    <group
                        position-y={0.4}
                        position-z={-1}
                        rotation-y={Math.PI / 3}
                        rotation-z={Math.PI / 6}
                        scale={0.5}
                    >
                        <SpaceShip />
                    </group>
                </Float>
                <group position={[0, -0.1 * height, 0.1]}>
                    <Text
                        fontSize={0.5}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        outlineColor={'#9c5314'}
                        outlineOffsetX={'5%'}
                        outlineOffsetY={'5%'}
                    >
                        Swiftness
                        <meshBasicMaterial color={'white'} toneMapped={false} />
                    </Text>
                    <group position={[0, -0.07 * height, 0]}>
                        <Text
                            fontSize={0.18}
                            fontWeight={700}
                            letterSpacing={-0.02}
                            textAlign="center"
                            lineHeight={1.1}
                        >
                            With our years of experience and our team synergy,{'\n'} we can deliver your project in a
                            few weeks only.
                            <meshBasicMaterial color={'white'} toneMapped={false} />
                        </Text>
                    </group>
                </group>
            </group>
            <group position={[0, -0.3 * height, 0]}>
                <Float
                    speed={2} // Animation speed, defaults to 1
                    rotationIntensity={1} // XYZ rotation intensity, defaults to 1
                    floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[-0.2, 0]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                >
                    <group position-y={-0.1} rotation={[Math.PI / 4, Math.PI / 6, 0]} scale={[1.2, 1.2, 1.5]}>
                        <Book />
                    </group>
                </Float>
                <group position={[0, -0.1 * height, 0.1]}>
                    <Text
                        fontSize={0.5}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        outlineColor={'#643e09'}
                        outlineOffsetX={'5%'}
                        outlineOffsetY={'5%'}
                    >
                        Communication
                        <meshBasicMaterial color={'white'} toneMapped={false} />
                    </Text>
                    <group position={[0, -0.07 * height, 0]}>
                        <Text
                            fontSize={0.18}
                            fontWeight={700}
                            letterSpacing={-0.02}
                            textAlign="center"
                            lineHeight={1.1}
                        >
                            Communication is key for us, we keep you updated {'\n'} with regular update on the developed
                            features or the issues encountered.
                            <meshBasicMaterial color={'white'} toneMapped={false} />
                        </Text>
                    </group>
                </group>
            </group>
        </group>
    );
};
