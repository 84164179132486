import { Line, Text } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useRef, useState } from 'react';
import { HighlightImage } from './HighlightImage';
import { Group, MathUtils } from 'three';

type Highlight = {
    title: string;
    description: string;
    imageUrl: string;
};

const aspectRatio = 1;

export const Highlights = ({ highlights, isRight }: { highlights: Highlight[]; isRight: boolean }) => {
    const { width, height } = useThree((state) => state.viewport);
    const imageHeight = height / 10;
    const imageWidth = imageHeight / aspectRatio;

    const isRightMultiplier = isRight ? -1 : 1;

    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const lineGroupRef = useRef<Group>(null);

    useFrame((_, delta) => {
        if (!lineGroupRef.current) {
            return;
        }

        lineGroupRef.current.position.y = MathUtils.damp(
            lineGroupRef.current.position.y,
            -selectedIndex * imageHeight - imageHeight / 2,
            8,
            delta
        );
    });

    const maximumHighlights = 5;
    const maximumTextWidth = width * 0.4;
    const maximumHeight = maximumHighlights * imageHeight;
    const textPaddingHorizontal = 0.4;
    const textPaddingVertical = 0.02;
    const textMarginTop = 0.1;

    return (
        <group position-y={-0.6}>
            <group position-y={-imageHeight / 2}>
                {highlights.map(({ imageUrl }, index) => {
                    return (
                        <group
                            key={index}
                            position-y={-index * imageHeight}
                            position-x={isRight ? -imageWidth / 2 : imageWidth / 2}
                        >
                            <HighlightImage
                                imageUrl={imageUrl}
                                imageIndex={index}
                                imageWidth={imageWidth}
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                            />
                        </group>
                    );
                })}
            </group>

            <group position-x={isRightMultiplier * (imageWidth + 0.2)}>
                <Line
                    points={[
                        [0, 0, 0],
                        [0, -maximumHighlights * imageHeight, 0],
                    ]} // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
                    lineWidth={5} // In pixels (default)
                    segments // If true, renders a THREE.LineSegments2. Otherwise, renders a THREE.Line2
                    toneMapped={false}
                    color={'#ffffff'}
                />
            </group>
            <group ref={lineGroupRef} position-x={isRightMultiplier * (imageWidth + 0.2)} position-y={imageHeight / 2}>
                <Line
                    points={[
                        [0, 0, 0],
                        [-isRightMultiplier * 0.1, 0, 0],
                    ]} // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
                    lineWidth={5} // In pixels (default)
                    segments // If true, renders a THREE.LineSegments2. Otherwise, renders a THREE.Line2
                    toneMapped={false}
                    color={'#ffffff'}
                />
            </group>
            <group position-x={isRightMultiplier * (imageWidth + 0.4)} position-y={-textMarginTop}>
                <Text
                    anchorX={isRight ? 'right' : 'left'}
                    anchorY={'top'}
                    textAlign={isRight ? 'right' : 'left'}
                    fontSize={0.3}
                    fontWeight={700}
                    letterSpacing={-0.02}
                    lineHeight={1.4}
                    maxWidth={maximumTextWidth}
                    outlineColor={'#000000'}
                    outlineOffsetX={'5%'}
                    outlineOffsetY={'5%'}
                >
                    {highlights?.[selectedIndex].title}
                    <meshBasicMaterial color={'#ffffff'} toneMapped={false} />
                </Text>
                <Text
                    anchorX={isRight ? 'right' : 'left'}
                    anchorY={'top'}
                    textAlign={isRight ? 'right' : 'left'}
                    fontSize={0.18}
                    fontWeight={700}
                    letterSpacing={-0.02}
                    lineHeight={1.4}
                    maxWidth={maximumTextWidth}
                    outlineColor={'#000000'}
                    outlineOffsetX={'5%'}
                    outlineOffsetY={'5%'}
                >
                    {'\n\n'}
                    {highlights?.[selectedIndex].description}
                    <meshBasicMaterial color={'#ffffff'} toneMapped={false} />
                </Text>
            </group>
            {/* <mesh
                position-z={-0.01}
                position-x={isRightMultiplier * (imageWidth + 0.4 + maximumTextWidth / 2)}
                position-y={-maximumHeight / 2}
                scale-x={maximumTextWidth + textPaddingHorizontal}
                scale-y={maximumHeight + 2 * textPaddingVertical}
            >
                <planeGeometry />
                <meshBasicMaterial color="#3e6483" opacity={0.2} transparent toneMapped={false} />
            </mesh> */}
        </group>
    );
};
