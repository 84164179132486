import { Float } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { BufferAttribute, BufferGeometry, Group, MathUtils, Texture } from 'three';

export const ImageGalleryImage = ({
    aspectRatio,
    isRight,
    isDesktop,
    image,
    imageIndex,
    numberOfImages,
    imageWidth,
    selectedIndex,
    setSelectedIndex,
}: {
    aspectRatio: number;
    isRight: boolean;
    isDesktop?: boolean;
    image: Texture;
    imageIndex: number;
    numberOfImages: number;
    imageWidth: number;
    selectedIndex: number | null;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
    const imageHeight = imageWidth * aspectRatio;

    const geometry = getRoundedRectangleGeometry(
        imageWidth,
        imageWidth * aspectRatio,
        isDesktop ? 0.02 * imageWidth : 0.1 * imageWidth,
        10
    );

    const imageRef = useRef<Group>(null);

    useFrame((_, delta) => {
        if (!imageRef.current) {
            return;
        }

        const scaleFactor = isDesktop ? 4 : 3;
        const maxNumberOfImages = isDesktop ? 4 : 6;

        const isSelected = selectedIndex === imageIndex;
        const orderedIndex = isRight ? numberOfImages - 1 - imageIndex : imageIndex;

        imageRef.current.scale.x = MathUtils.damp(imageRef.current.scale.x, isSelected ? scaleFactor : 1, 4, delta);
        imageRef.current.scale.y = MathUtils.damp(imageRef.current.scale.y, isSelected ? scaleFactor : 1, 4, delta);

        imageRef.current.position.z = MathUtils.damp(imageRef.current.position.z, isSelected ? 1.1 : 0, 16, delta);

        imageRef.current.position.y = MathUtils.damp(
            imageRef.current.position.y,
            isSelected ? ((imageHeight * 0.9) / 2) * (scaleFactor - 1) : 0,
            4,
            delta
        );

        imageRef.current.position.x = MathUtils.damp(
            imageRef.current.position.x,
            isSelected
                ? ((imageWidth * 0.9) / 2) *
                      (scaleFactor - 1) *
                      (orderedIndex / (maxNumberOfImages - 1) - 0.5) *
                      2 *
                      (isRight ? 1 : -1)
                : 0,
            4,
            delta
        );
    });

    return (
        <Float
            speed={3} // Animation speed, defaults to 1
            rotationIntensity={1} // XYZ rotation intensity, defaults to 1
            floatIntensity={0.5} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        >
            <group ref={imageRef}>
                <mesh
                    geometry={geometry}
                    onPointerMove={() => {
                        if (!selectedIndex) {
                            setSelectedIndex(imageIndex);
                        }
                    }}
                    onPointerLeave={() => {
                        setSelectedIndex(null);
                    }}
                >
                    <meshBasicMaterial map={image} toneMapped={false} />
                </mesh>
            </group>
        </Float>
    );
};

// indexed BufferGeometry
// non indexed BufferGeometry

function getRoundedRectangleGeometry(w: number, h: number, r: number, s: number) {
    // width, height, radius corner, smoothness

    // helper const's
    const wi = w / 2 - r; // inner width
    const hi = h / 2 - r; // inner height
    const w2 = w / 2; // half width
    const h2 = h / 2; // half height
    const ul = r / w; // u left
    const ur = (w - r) / w; // u right
    const vl = r / h; // v low
    const vh = (h - r) / h; // v high

    const positions = [
        -wi,
        -h2,
        0,
        wi,
        -h2,
        0,
        wi,
        h2,
        0,
        -wi,
        -h2,
        0,
        wi,
        h2,
        0,
        -wi,
        h2,
        0,
        -w2,
        -hi,
        0,
        -wi,
        -hi,
        0,
        -wi,
        hi,
        0,
        -w2,
        -hi,
        0,
        -wi,
        hi,
        0,
        -w2,
        hi,
        0,
        wi,
        -hi,
        0,
        w2,
        -hi,
        0,
        w2,
        hi,
        0,
        wi,
        -hi,
        0,
        w2,
        hi,
        0,
        wi,
        hi,
        0,
    ];

    const uvs = [
        ul,
        0,
        ur,
        0,
        ur,
        1,
        ul,
        0,
        ur,
        1,
        ul,
        1,
        0,
        vl,
        ul,
        vl,
        ul,
        vh,
        0,
        vl,
        ul,
        vh,
        0,
        vh,
        ur,
        vl,
        1,
        vl,
        1,
        vh,
        ur,
        vl,
        1,
        vh,
        ur,
        vh,
    ];

    let phia = 0;
    let phib, xc, yc, uc, vc, cosa, sina, cosb, sinb;

    for (let i = 0; i < s * 4; i++) {
        phib = (Math.PI * 2 * (i + 1)) / (4 * s);

        cosa = Math.cos(phia);
        sina = Math.sin(phia);
        cosb = Math.cos(phib);
        sinb = Math.sin(phib);

        xc = i < s || i >= 3 * s ? wi : -wi;
        yc = i < 2 * s ? hi : -hi;

        positions.push(xc, yc, 0, xc + r * cosa, yc + r * sina, 0, xc + r * cosb, yc + r * sinb, 0);

        uc = i < s || i >= 3 * s ? ur : ul;
        vc = i < 2 * s ? vh : vl;

        uvs.push(uc, vc, uc + ul * cosa, vc + vl * sina, uc + ul * cosb, vc + vl * sinb);

        phia = phib;
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new BufferAttribute(new Float32Array(positions), 3));
    geometry.setAttribute('uv', new BufferAttribute(new Float32Array(uvs), 2));

    return geometry;
}
