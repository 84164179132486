import { DragControls, Float, Text } from '@react-three/drei';
import { PowerBank } from './PowerBank';
import { useRef, useState } from 'react';
import * as THREE from 'three';
import { Group } from 'three';

export function DragablePowerBank({
    onDragEnd,
    ...props
}: JSX.IntrinsicElements['group'] & { onDragEnd: (position: THREE.Vector3) => void }) {
    const groupRef = useRef<Group>(null);

    // Set initial states for position and rotation
    const [meshPosition] = useState(new THREE.Vector3(0, 0, 0));

    function handleOnDrag(local: THREE.Matrix4) {
        const position = new THREE.Vector3();
        const scale = new THREE.Vector3();
        const quaternion = new THREE.Quaternion();
        local.decompose(position, quaternion, scale);
        meshPosition.copy(position);
    }

    return (
        <group dispose={null} ref={groupRef}>
            <DragControls onDrag={handleOnDrag} onDragEnd={() => onDragEnd(meshPosition)}>
                <group
                    {...props}
                    onPointerEnter={() => {
                        document.body.style.cursor = 'pointer';
                    }}
                    onPointerLeave={() => {
                        document.body.style.cursor = 'default';
                    }}
                >
                    <Float
                        speed={3} // Animation speed, defaults to 1
                        rotationIntensity={1} // XYZ rotation intensity, defaults to 1
                        floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                        floatingRange={[-0.2, 0.2]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                    >
                        <PowerBank />

                        <group>
                            <Text
                                position={[0, -1.5, 0]}
                                fontSize={0.2}
                                fontWeight={700}
                                letterSpacing={-0.02}
                                textAlign="center"
                                lineHeight={1}
                            >
                                Drag me !
                                <meshBasicMaterial color={'white'} toneMapped={false} />
                            </Text>
                        </group>
                    </Float>
                </group>
            </DragControls>
        </group>
    );
}
