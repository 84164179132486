import styled from 'styled-components';
import { WaveLetter } from '../letterEffect/WaveLetter';

export const Credits = () => {
    return (
        <Container>
            <TitleContainer>
                <Title>
                    <WaveLetter letter={'C'} index={0} />
                    <WaveLetter letter={'R'} index={1} />
                    <WaveLetter letter={'E'} index={2} />
                    <WaveLetter letter={'D'} index={3} />
                    <WaveLetter letter={'I'} index={4} />
                    <WaveLetter letter={'T'} index={5} />
                    <WaveLetter letter={'S'} index={6} />
                </Title>
            </TitleContainer>
            <PrinciplesContainer />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
`;

const PrinciplesContainer = styled.div`
    width: 100%;
    flex: 1;
`;
