/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { forwardRef } from 'react';
import { GroupProps } from '@react-three/fiber';
import { type GLTF } from 'three-stdlib';
import { RoundedBox, useGLTF, useTexture } from '@react-three/drei';

type IphoneGLTFResult = GLTF & {
    nodes: {
        Circle038: THREE.Mesh;
        Circle038_1: THREE.Mesh;
        Circle038_2: THREE.Mesh;
        Circle038_3: THREE.Mesh;
        Circle038_4: THREE.Mesh;
        AntennaLineBottom001: THREE.Mesh;
        AntennaLineTop001: THREE.Mesh;
        BackCameraBottomGreyRing001: THREE.Mesh;
        AppleLogo001: THREE.Mesh;
        BackCameraBottomLens001: THREE.Mesh;
        BackCameraP1001: THREE.Mesh;
        FlashBG001: THREE.Mesh;
        CameraBump001: THREE.Mesh;
        BackCameraTopLens001: THREE.Mesh;
        FrontSpeakerBG001: THREE.Mesh;
        FrontCameraContainer001: THREE.Mesh;
        MuteSwitch001: THREE.Mesh;
        iPhoneLogo001: THREE.Mesh;
        BackCameraTopGreyRing001: THREE.Mesh;
        Circle031: THREE.Mesh;
        Circle031_1: THREE.Mesh;
        VolumeButtons001: THREE.Mesh;
        Circle032: THREE.Mesh;
        Circle032_1: THREE.Mesh;
        SCREEN: THREE.Mesh;
    };
    materials: {
        ['FrameGrey.001']: THREE.MeshStandardMaterial;
        ['Front.001']: THREE.MeshStandardMaterial;
        ['Antennaline.001']: THREE.MeshStandardMaterial;
        ['BackGrey.001']: THREE.MeshStandardMaterial;
        ['Rubber.001']: THREE.MeshStandardMaterial;
        ['BackCaneraGrayRIng.002']: THREE.MeshStandardMaterial;
        ['AppleLogo.001']: THREE.MeshStandardMaterial;
        ['Lens.001']: THREE.MeshStandardMaterial;
        ['Black.015']: THREE.MeshStandardMaterial;
        ['PinkFlash.002']: THREE.MeshStandardMaterial;
        ['Frame.001']: THREE.MeshStandardMaterial;
        ['FrontSpeaker.001']: THREE.MeshStandardMaterial;
        ['FrontCameraBlack.002']: THREE.MeshStandardMaterial;
        ['iPhoneLogo.001']: THREE.MeshStandardMaterial;
        ['Black.014']: THREE.MeshStandardMaterial;
        ['Display.002']: THREE.MeshStandardMaterial;
    };
};

export const Iphone = forwardRef<THREE.Group, GroupProps>(({ ...props }: GroupProps, ref) => {
    const { nodes, materials } = useGLTF('/objects/iphone.glb') as IphoneGLTFResult;
    const textureRed = useTexture('/objects/iphone-screen-dark.png');
    // textureRed.flipY = false;
    // textureRed.rotation = 0.1;
    // textureRed.rotation = -Math.PI * 0.5;
    // textureRed.center.x = 0.5;
    // textureRed.center.y = 0.5;
    textureRed.repeat.set(0.75, 0.33);
    textureRed.wrapS = THREE.ClampToEdgeWrapping;
    textureRed.wrapT = THREE.ClampToEdgeWrapping;

    return (
        <group ref={ref} {...props} dispose={null}>
            <group position={[0, 1.56, 0]}>
                <mesh geometry={nodes.Circle038.geometry} material={nodes.Circle038.material} />
                <mesh geometry={nodes.Circle038_1.geometry} material={materials['Front.001']} />
                <mesh geometry={nodes.Circle038_2.geometry} material={nodes.Circle038_2.material} />
                <mesh geometry={nodes.Circle038_3.geometry} material={materials['BackGrey.001']} />
                <mesh geometry={nodes.Circle038_4.geometry} material={materials['Rubber.001']} />
                <mesh
                    geometry={nodes.AntennaLineBottom001.geometry}
                    material={nodes.AntennaLineBottom001.material}
                    position={[0, -2.68, 0]}
                />
                <mesh
                    geometry={nodes.AntennaLineTop001.geometry}
                    material={nodes.AntennaLineTop001.material}
                    position={[0, 0.02, 0]}
                />
                <mesh
                    geometry={nodes.BackCameraBottomGreyRing001.geometry}
                    material={nodes.BackCameraBottomGreyRing001.material}
                    position={[0.7, 0.88, -0.09]}
                />
                <mesh
                    geometry={nodes.AppleLogo001.geometry}
                    material={materials['AppleLogo.001']}
                    position={[0.17, 0.52, -0.08]}
                />
                <mesh
                    geometry={nodes.BackCameraBottomLens001.geometry}
                    material={nodes.BackCameraBottomLens001.material}
                    position={[0.7, 0.88, -0.08]}
                />
                <mesh
                    geometry={nodes.BackCameraP1001.geometry}
                    material={materials['Black.015']}
                    position={[0.7, 1.03, -0.09]}
                />
                <mesh
                    geometry={nodes.FlashBG001.geometry}
                    material={materials['PinkFlash.002']}
                    position={[0.71, 1.03, -0.09]}
                />
                <mesh
                    geometry={nodes.CameraBump001.geometry}
                    material={nodes.CameraBump001.material}
                    position={[0.7, 1.04, -0.08]}
                />
                <mesh
                    geometry={nodes.BackCameraTopLens001.geometry}
                    material={nodes.BackCameraTopLens001.material}
                    position={[0.7, 1.18, -0.08]}
                />
                <mesh
                    geometry={nodes.FrontSpeakerBG001.geometry}
                    material={materials['FrontSpeaker.001']}
                    position={[0.16, 1.32, 0.08]}
                />
                <mesh
                    geometry={nodes.FrontCameraContainer001.geometry}
                    material={materials['FrontCameraBlack.002']}
                    position={[0.34, 1.32, 0.08]}
                />
                <mesh
                    geometry={nodes.MuteSwitch001.geometry}
                    material={nodes.MuteSwitch001.material}
                    position={[-0.65, 0.92, 0.01]}
                />
                <mesh
                    geometry={nodes.iPhoneLogo001.geometry}
                    material={materials['iPhoneLogo.001']}
                    position={[0.2, -1.18, -0.08]}
                />
                <mesh
                    geometry={nodes.BackCameraTopGreyRing001.geometry}
                    material={nodes.BackCameraTopGreyRing001.material}
                    position={[0.7, 1.18, -0.09]}
                />
                <group position={[0.98, -0.04, 0]}>
                    <mesh geometry={nodes.Circle031.geometry} material={materials['Black.014']} />
                    <mesh geometry={nodes.Circle031_1.geometry} material={nodes.Circle031_1.material} />
                </group>
                <mesh
                    geometry={nodes.VolumeButtons001.geometry}
                    material={nodes.VolumeButtons001.material}
                    position={[-0.66, 0.21, 0]}
                />
                <group position={[0.97, 0.56, 0]}>
                    <mesh geometry={nodes.Circle032.geometry} material={nodes.Circle032.material} />
                    <mesh geometry={nodes.Circle032_1.geometry} material={nodes.Circle032_1.material} />
                </group>
                <mesh>
                    <RoundedBox
                        args={[1.52, 3.25, 0.25]} // Width, height, depth. Default is [1, 1, 1]
                        position={[0.155, -0.24, 0.05]}
                        radius={0.1} // Radius of the rounded corners. Default is 0.05
                        smoothness={10} // The number of curve segments. Default is 4
                        bevelSegments={0} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                        creaseAngle={1} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
                    >
                        <meshBasicMaterial map={textureRed} attach="material-0" toneMapped={false} />
                    </RoundedBox>
                </mesh>
            </group>
        </group>
    );
});

useGLTF.preload('/objects/iphone.glb');
useTexture.preload('/objects/iphone-screen-dark.png');
