/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { type GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
    nodes: {
        Cube012: THREE.Mesh;
        Cube012_1: THREE.Mesh;
        Cube012_2: THREE.Mesh;
    };
    materials: {
        ['Brown.018']: THREE.MeshStandardMaterial;
        ['White.040']: THREE.MeshStandardMaterial;
        ['Metal.083']: THREE.MeshStandardMaterial;
    };
};

export default function Book(props: JSX.IntrinsicElements['group']) {
    const { nodes, materials } = useGLTF('/objects/book.gltf') as GLTFResult;
    return (
        <group {...props} dispose={null}>
            <group rotation={[Math.PI / 2, 0, 0]}>
                <mesh geometry={nodes.Cube012.geometry} material={materials['Brown.018']} />
                <mesh geometry={nodes.Cube012_1.geometry} material={materials['White.040']} />
                <mesh geometry={nodes.Cube012_2.geometry} material={materials['Metal.083']} />
            </group>
        </group>
    );
}

useGLTF.preload('/objects/book.gltf');
